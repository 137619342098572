<template>
    <div class=" ">
      <div class="row">
        <div class="col-9  fs-4  page-heading-parter col-sm-9 col-lg-10">
          <p class="mypracticeMobile" style="margin-left: 20px;">Orders No: {{ 10000 + Number(itemId) }}</p>
        </div>
        <div class="col-2 col-sm-3 col-lg-2">
          <div class="my-3 text-center">
            <button class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="GoSetting()">Back</button>
          </div>
        </div>
      </div>
      <div class="hr-border"></div>
    </div>
  
  
    <div class="nav-tabs-container">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" :class="{ active: currentTab === 'tab1' }" href="#tab1" @click="selectTab('tab1')">
            Order
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{ active: currentTab === 'tab2', disabled:orderAccepted}"  href="#tab2" @click="selectTab('tab2')">
            Shipment
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{ active: currentTab === 'tab4',disabled:orderAccepted }" href="#tab4" @click="selectTab('tab4')">
            Delivery
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{ active: currentTab === 'tab5',disabled:orderAccepted  }" href="#tab5" @click="selectTab('tab5')">
            Feedback
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{ active: currentTab === 'tab3' }" href="#tab3" @click="selectTab('tab3')">
            Progress
          </a>
        </li>
  
      </ul>
  
      <!-- Tab Content -->
      <div class="tab-content">
        <div :class="{ 'tab-pane': true, 'active': currentTab === 'tab1' }" id="tab1">
          <!-- Content for Tab 1 -->
  
          <div class="row my-12">
            <div class="col-md-4 col-2 col-lg-2"></div>
            <div class="col-md-4 col-12 col-lg-8  ">
  
  
  
  
  
              <fieldset class="curve-box  m-0  ">
                <legend class="subHeadingText">Clinic details</legend>
  
                <div class="row  ">
                  <div class="col-12 col-md-6 col-lg-4 ">
                    <label>Name</label>
                    <div class="form-group input-group">
                      <input type="text" class="form-control cursor UpdateTextAlignment" v-model="clinicDetails.name"
                        disabled>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4 ">
                    <label>Clinician</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" v-model="clinicDetails.clinician"
                      disabled>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4 ">
                    <label>Clinician phone</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment"
                      v-model="clinicDetails.clinicianPhone" disabled>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-md-4 col-2 col-lg-1"></div>
          </div>
  
  
          <div class="row my-12">
            <div class="col-md-4 col-2"></div>
            <div class="col-md-4 col-12">
  
  
  
  
  
              <fieldset class="curve-box m-0">
                <legend class="subHeadingText">Patient details</legend>
  
                <div class="row ">
                  <div class="col-12 col-md-6 col-lg-4 ">
                    <label>Name</label>
                    <div class="form-group input-group">
                      <input type="text" class="form-control cursor UpdateTextAlignment" v-model="patientDetails.name"
                        disabled>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-lg-4 ">
                    <label>Age</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" v-model="patientDetails.age"
                      disabled>
                  </div>
                  <div class="col-6 col-md-6 col-lg-4 ">
                    <label>Gender</label>
                    <input type="text" class="form-control cursor UpdateTextAlignment" v-model="patientDetails.gender"
                      disabled>
                  </div>
                  <div class="col-6 col-md-6 col-lg-4">
                    <label>Height</label>
                    <div class="d-flex align-items-center mt-2">
                      <input type="text" class="form-control cursor UpdateTextAlignment" style="flex: 1;"
                        v-model="patientDetails.height" disabled>
                      <span class="ml-2 ms-2">cm</span>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 col-lg-4">
                    <label>Weight</label>
                    <div class="d-flex align-items-center mt-2">
                      <input type="text" class="form-control cursor UpdateTextAlignment" style="flex: 1;"
                        v-model="patientDetails.weight" disabled>
                      <span class="ml-2 ms-2">kg</span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-md-4 col-2"></div>
          </div>
  
  
          <fieldset class="curve-box m-0" >
            <legend class="subHeadingText">Case sheet</legend>
            <div class="row ">
              
              <div class="col-12 col-md-6 col-lg-12">
                <div class="form-group input-group ">
                  <textarea class="form-control cursor UpdateTextAlignment" rows="2"
                    v-model="this.otherDatas.caseSheet[0]" disabled></textarea>
                </div>
                <div class="form-group input-group my-2">
                  <textarea class="form-control cursor UpdateTextAlignment" rows="2"
                    v-model="this.otherDatas.caseSheet[1]" disabled></textarea>
                </div>
                <div class="form-group input-group">
                  <textarea class="form-control cursor UpdateTextAlignment" rows="2"
                    v-model="this.otherDatas.caseSheet[2]" disabled></textarea>
                </div>
              </div>
            </div>
          </fieldset>
  
  
          <div class="row ">
            <div class="col-md-3 "></div>
            <div class="col-md-3 col-12">
              <fieldset class="curve-box left-right m-0">
                <legend class="subHeadingText">Foot arch</legend>
                <div class="row ">
                  <div class="col-6">
                    <label>Left foot</label>
                  </div>
                  <div class="col-6 my-1">
                    <div class="form-group input-group">
                      <input type="text" class="form-control cursor UpdateTextAlignment" v-model="footArch.left" disabled>
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Right foot</label>
                  </div>
                  <div class="col-6">
                    <div class="form-group input-group">
                      <input type="text" class="form-control cursor UpdateTextAlignment" v-model="footArch.right"
                        disabled>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
  
            <!-- Joint Mobility Section -->
            <div class="col-md-3 col-12">
              <fieldset class="curve-box left-right m-0" >
                <legend class="subHeadingText">Joint mobility</legend>
                <div class="row ">
                  <div class="col-6">
                    <label>Left foot</label>
                  </div>
                  <div class="col-6 my-1">
                    <div class="form-group input-group">
                      <input type="text" class="form-control cursor UpdateTextAlignment" v-model="JointMobility.left"
                        disabled>
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Right foot</label>
                  </div>
                  <div class="col-6">
                    <div class="form-group input-group">
                      <input type="text" class="form-control cursor UpdateTextAlignment" v-model="JointMobility.right"
                        disabled>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
  
            <div class="col-md-3 "></div>
          </div>
  

       
          <div class="row my-12">
          <div class="col-1 col-lg-3"></div>
          <div class="col-12 col-lg-6 ">
            <fieldset class="curve-box m-0">
              <legend class="subHeadingText">Foot measurements</legend>
              <p>Input all measurements in cm.</p>

              <table class="offload-table">
                <thead>
                  <tr class=" ">
                    <th>Parameter</th>
                    <th class="text-center">Left </th>
                    <th class="text-center">Right </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(label, key) in footMeasurement" :key="key">
                    <td> <span class="" style="cursor: pointer; " data-bs-toggle="modal" data-bs-target="#exampleModal"
                        @click="showModal(key)">
                        {{ key }}
                      </span>


                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content ">
                            <div class="modal-header  bg-color">
                              <h5 class="modal-title text-white" id="exampleModalLabel">{{ selectedMeasurement.key }}
                              </h5>
                              <button type="button" class="btn-close text-white" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                            </div>
                            <div class="modal-body  m-3" style="border: 2px solid #00979e;">
                              <img width="100%" :src="selectedMeasurement.image" alt="Measurement image" />
                            </div>

                            <div>
                              <p class="text-center mx-4">{{ selectedMeasurement.text }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="text-center">
                        <input class="foot-input " type="number" disabled maxlength="2"
                          v-model="footMeasurement[key].left" />

                      </div>
                    </td>
                    <td>
                      <div class="text-center ">
                        <input class="foot-input" type="number" disabled maxlength="2"
                          v-model="footMeasurement[key].right" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </fieldset>
          </div>
          <div class="col-1 col-lg-3"></div>
        </div>
       
          <div class="row ">
                  <div class="col-12 col-lg-2  col-md-6"></div>

                  <div class="col-12 col-lg-8  col-md-6">
                    <fieldset class="curve-box mt-2">
                      <legend class="subHeadingText">Anatomical location of the pathology/ symptom</legend>



                      <leftFootPad :staticImagesArray="leftCanvas" ></leftFootPad>
                      <rightFootPad :staticImagesArray="rightCanvas" ></rightFootPad>

                    </fieldset>


                  </div>
                  <div class="col-12 col-lg-2  col-md-6"></div>
                </div>
  
          <div class="row ">
            <div class="col-md-3 col-1 "></div>
            <div class="col-md-1 col-1 "></div>
  
            <div class="col-md-3 col-lg-5 col-12">
              <fieldset class="curve-box m-0">
                <legend class="subHeadingText">Areas to offload</legend>
                <table class="offload-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="foot-column">Left foot</th>
                      <th class="foot-column">Right foot</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in areasToOffload" :key="key">
                      <td>{{ key }}</td>
                      <td :class="{ 'green-background': value.left === 'Yes' }">
                      </td>
                      <td :class="{ 'green-background': value.right === 'Yes' }">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
            <div class="col-md-3 col-1"></div>
          </div>
  
          <div class="row ">
            <div class="col-md-2 col-1"></div>
            <div class="col-md-2 col-1"></div>
  
            <div class="col-md-3  col-lg-5 col-12">
              <fieldset class="curve-box m-0">
                <legend class="subHeadingText">Offloading methods</legend>
                <table class="offload-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th class="foot-column">Left foot</th>
                      <th class="foot-column">Right foot</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, key) in Offloading_methods" :key="key">
                      <td>{{ key }}</td>
                      <td :class="{ 'green-background': value.left === 'Yes' }">
                      </td>
                      <td :class="{ 'green-background': value.right === 'Yes' }">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
            <div class="col-md-3 col-1"></div>
          </div>
  
  
          <fieldset class="curve-box m-0">
            <legend class="subHeadingText"> Recommendations</legend>
            <div class="row ">
              <div class="col-12 col-md-6 col-lg-12">
                <div class="form-group input-group">
                  <textarea class="form-control cursor UpdateTextAlignment " rows="5"
                   disabled v-model="otherDatas.recommandations"></textarea>
                </div>
              </div>
            </div>
          </fieldset>
  
  
          <div class="row ">
            <div class="col-md-2 col-2"></div>
            <div class="col-md-2 col-2"></div>
  
            <div class="col-md-3 col-lg-3 col-12">
              <fieldset class="curve-box left-right m-0">
                <legend class="subHeadingText">Footwear</legend>
                <div class="row my-2">
                  <div class="col-6">
                    <label>Size (UK)</label>
                  </div>
                  <div class="col-6 my-1">
                    <input type="text" class="form-control" style="cursor: pointer;" v-model="otherDatas.size" disabled>
                  </div>
                  <div class="col-6 my-1">
                    <label>Model</label>
                  </div>
                  <div class="col-6 my-1">
                    <input type="text" class="form-control" style="cursor: pointer;" v-model="otherDatas.model" disabled>
                  </div>
  
                  <div class="col-6 my-1">
                    <label>Colour</label>
                  </div>
                  <div class="col-6 my-1" >
                    <input type="text" class="form-control" style="cursor: pointer;" v-model="this.colour_name" disabled>
                  </div>
  
                  
                </div>
              </fieldset>
  
            </div>
  
            <!-- Joint Mobility Section -->
            <div class="col-md-3 col-8">
  
            </div>
  
            <div class="col-md-3 col-2"></div>
          </div>
  
  
          <div class="row ">
          <div class="col-md-1 col-1"></div>
          <div class="col-md-12 col-lg-10 col-12">
            <fieldset class="curve-box m-0">
              <legend class="subHeadingText"> Supporting artifacts</legend>

              <div class="accordion" id="offloadAccordion">
                <!-- Accordion Item 1 -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      PPS Pdf document
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#offloadAccordion">
                    <div class="accordion-body">
                      <!-- Embed the PDF file -->
                      <embed :src="upload_files.ppsPdfFile" type="application/pdf" class="d-block w-100"
                        height="500px" />
                    </div>
                  </div>

                </div>

                <!-- Accordion Item 2 -->
                <div class="accordion-item" v-if="upload_files.ppsVideoFile">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      PPS video
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                    data-bs-parent="#offloadAccordion">
                    <div class="accordion-body">
                      <!-- Embed the Video -->
                      <video width="100%" controls>
                        <source :src="upload_files.ppsVideoFile" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>

                    </div>
                  </div>
                </div>

                <!-- Accordion Item 3 -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Gait videos
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#offloadAccordion">
                    <div class="accordion-body">
                      <!-- Tabs -->
                      <ul class="nav nav-tabs" id="gaitVideosTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <a class="nav-link active" id="tab11-tab" data-bs-toggle="tab" href="#tab11" role="tab"
                            aria-controls="tab11" aria-selected="true">Anterior</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-link" id="tab22-tab" data-bs-toggle="tab" href="#tab22" role="tab"
                            aria-controls="tab22" aria-selected="false"> Bilateral</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-link" id="tab33-tab" data-bs-toggle="tab" href="#tab33" role="tab"
                            aria-controls="tab33" aria-selected="false">Posterior</a>
                        </li>
                      </ul>
                      <div class="tab-content" id="gaitVideosTabContent">
                        <!-- Tab 1 Content -->
                        <div class="tab-pane fade show active" id="tab11" role="tabpanel" aria-labelledby="tab11-tab">
                          <video width="100%" controls>
                            <source :src="gait_videos.anteriorVideoFile" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                        </div>
                        <!-- Tab 2 Content -->
                        <div class="tab-pane fade" id="tab22" role="tabpanel" aria-labelledby="tab22-tab">
                          <video width="100%" controls>
                            <source :src="gait_videos.bilateralVideoFile" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                        </div>
                        <!-- Tab 3 Content -->
                        <div class="tab-pane fade" id="tab33" role="tabpanel" aria-labelledby="tab33-tab">
                          <video width="100%" controls>
                            <source :src="gait_videos.posteriorVideoFile" type="video/mp4">
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- Accordion Item 4 -->
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Photographs
                    </button>
                  </h2>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                    data-bs-parent="#offloadAccordion">
                    <div class="accordion-body">
                      <ul class="nav nav-tabs" id="mainTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <a class="nav-link active" id="tab21-tab" data-bs-toggle="tab" href="#tab21" role="tab"
                            aria-controls="tab21" aria-selected="true">B/L Foot</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-link" id="tab20-tab" data-bs-toggle="tab" href="#tab20" role="tab"
                            aria-controls="tab20" aria-selected="false">Left foot</a>
                        </li>
                        <li class="nav-item" role="presentation">
                          <a class="nav-link" id="tab19-tab" data-bs-toggle="tab" href="#tab19" role="tab"
                            aria-controls="tab19" aria-selected="false">Right foot</a>
                        </li>
                      </ul>

                      <div class="tab-content" id="mainTabContent">
                        <div class="tab-pane fade show active" id="tab21" role="tabpanel" aria-labelledby="tab21-tab">
                          <ul class="nav nav-tabs" id="blFootTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <a class="nav-link active p-1 " id="tab51-tab" data-bs-toggle="tab" href="#tab51" role="tab"
                                aria-controls="tab51" aria-selected="true">Plantar</a>
                            </li>
                            <li class="nav-item" role="presentation">
                              <a class="nav-link p-1" id="tab52-tab" data-bs-toggle="tab" href="#tab52" role="tab"
                                aria-controls="tab52" aria-selected="false">Dorsal</a>
                            </li>
                            <li class="nav-item" role="presentation">
                              <a class="nav-link p-1" id="tab53-tab" data-bs-toggle="tab" href="#tab53" role="tab"
                                aria-controls="tab53" aria-selected="false">Anterior</a>
                            </li>
                            <li class="nav-item" role="presentation">
                              <a class="nav-link p-1" id="tab54-tab" data-bs-toggle="tab" href="#tab54" role="tab"
                                aria-controls="tab54" aria-selected="false">Posterior</a>
                            </li>
                          </ul>
                          <div class="tab-content" id="blFootTabContent">
                            <div class="tab-pane fade show active" id="tab51" role="tabpanel"
                              aria-labelledby="tab51-tab">
                              <img :src="foot_photographs.plantarPhoto" class="d-block w-100 p-1" alt="Plantar Photo">
                              <!-- <img src="https://rxix-dss-charts-24.s3.ap-south-1.amazonaws.com/medical-records/foot_photographs/plantarPhoto/aecdaf87-640e-4245-90f8-d6353b9f60f0-20240923_093906%20%281%29.jpg" class="d-block w-100 p-1" alt="Plantar Photo"> -->

                            </div>
                            <div class="tab-pane fade" id="tab52" role="tabpanel" aria-labelledby="tab52-tab">
                              <img :src="foot_photographs.dorsalPhoto" class="d-block w-100 p-1" alt="Dorsal Photo">
                            </div>
                            <div class="tab-pane fade" id="tab53" role="tabpanel" aria-labelledby="tab53-tab">
                              <img :src="foot_photographs.anteriorPhoto" class="d-block w-100 p-1" alt="Anterior Photo">
                            </div>
                            <div class="tab-pane fade" id="tab54" role="tabpanel" aria-labelledby="tab54-tab">
                              <img :src="foot_photographs.posteriorPhoto" class="d-block w-100 p-1"
                                alt="Posterior Photo">
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade" id="tab20" role="tabpanel" aria-labelledby="tab20-tab">
                          <ul class="nav nav-tabs" id="leftFootTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <a class="nav-link p-1 active" id="tab61-tab" data-bs-toggle="tab" href="#tab61" role="tab"
                                aria-controls="tab61" aria-selected="true">Left Lateral Photo</a>
                            </li>
                            <li class="nav-item" role="presentation">
                              <a class="nav-link p-1" id="tab62-tab" data-bs-toggle="tab" href="#tab62" role="tab"
                                aria-controls="tab62" aria-selected="false">Left Medial Photo</a>
                            </li>
                          </ul>
                          <div class="tab-content" id="leftFootTabContent">
                            <div class="tab-pane fade show active" id="tab61" role="tabpanel"
                              aria-labelledby="tab61-tab">
                              <img :src="foot_photographs.leftLateralPhoto" class="d-block w-100 p-1"
                                alt="Left Lateral Photo">
                            </div>
                            <div class="tab-pane fade" id="tab62" role="tabpanel" aria-labelledby="tab62-tab">
                              <img :src="foot_photographs.leftMedialPhoto" class="d-block w-100 p-1"
                                alt="Left Medial Photo">
                            </div>
                          </div>
                        </div>

                        <div class="tab-pane fade" id="tab19" role="tabpanel" aria-labelledby="tab19-tab">
                          <ul class="nav nav-tabs" id="rightFootTab" role="tablist">
                            <li class="nav-item" role="presentation">
                              <a class="nav-link p-1 active" id="tab71-tab" data-bs-toggle="tab" href="#tab71" role="tab"
                                aria-controls="tab71" aria-selected="true">Right Lateral Photo</a>
                            </li>
                            <li class="nav-item" role="presentation">
                              <a class="nav-link p-1" id="tab72-tab" data-bs-toggle="tab" href="#tab72" role="tab"
                                aria-controls="tab72" aria-selected="false">Right Medial Photo</a>
                            </li>
                          </ul>
                          <div class="tab-content" id="rightFootTabContent">
                            <div class="tab-pane fade show active" id="tab71" role="tabpanel"
                              aria-labelledby="tab71-tab">
                              <img :src="foot_photographs.rightLateralPhoto" class="d-block w-100 p-1"
                                alt="Right Lateral Photo">
                            </div>
                            <div class="tab-pane fade" id="tab72" role="tabpanel" aria-labelledby="tab72-tab">
                              <img :src="foot_photographs.rightMedialPhoto" class="d-block w-100 p-1"
                                alt="Right Medial Photo">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      Foot sketches
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                    data-bs-parent="#offloadAccordion">
                    <div class="accordion-body">
                      <div class="row">
                        <div class="col-6   col-lg-6">
                          <div class="border ">
                            <p class="bg-color m-0 text-white text-center">Left Foot </p>
                            <img :src="foot_sketch_images.leftFootSketch" class="d-block w-100 p-1"
                              alt="Left Foot Sketch">
                          </div>


                        </div>
                        <div class="col-6  col-lg-6">

                          <div class="border ">
                            <p class="bg-color m-0 text-white text-center">Right Foot </p>
                            <img :src="foot_sketch_images.rightFootSketch" class="d-block w-100 p-1"
                              alt="Left Foot Sketch">
                          </div>


                        </div>

                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </fieldset>
          </div>
          <!-- <div class="col-md-3 col-1"></div> -->
        </div>
          <div class=" ">
            <div class="col-lg-6 col-12  ">
                    <label>Planned delivery date</label>
                  </div>
                  <div class="col-lg-3 col-12 my-1">
                    <input type="date" disabled id="datepicker"  
                      class="form-control" v-model="planned_delivery_date" />
                  </div>
            </div>
  
  
     
  
  
  
  
  
  
          <!-- <div class="row mt-2" v-for="(value, index) in acceptedOrder" :key="index">
  
            <router-link to="/doctor/manage/orders">
              <div class="SettingName">{{ 10000 + value.id }}</div>
          </router-link>
        
      </div> -->
  
        </div>
        <div :class="{ 'tab-pane': true, 'active': currentTab === 'tab2' }" id="tab2">
          <div class="row my-2">
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Patient</label>
              <div class="form-group input-group">
                <input type="text" class="form-control cursor UpdateTextAlignment" v-model="patientDetails.name" disabled>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Clinician</label>
              <input type="text" class="form-control cursor UpdateTextAlignment" v-model="clinicDetails.clinician"
                disabled>
            </div>
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Clinic</label>
              <input type="text" class="form-control cursor UpdateTextAlignment" v-model="clinicDetails.name" disabled>
            </div>
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Address</label>
              <div class="form-group input-group my-2">
                <input type="text" class="form-control cursor UpdateTextAlignment" v-model="clinicDetails.current_address"
                  disabled>
              </div>
              <div class="form-group input-group">
                <input type="text" class="form-control cursor UpdateTextAlignment" v-model="clinicDetails.city" disabled>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Pincode</label>
              <input type="text" class="form-control cursor UpdateTextAlignment" v-model="clinicDetails.pincode" disabled>
            </div>
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Phone</label>
              <input type="text" class="form-control cursor UpdateTextAlignment" v-model="clinicDetails.mobile" disabled>
            </div>
          </div>
          <div class="col-12 col-sm-4 col-lg-4 " v-if="hideUpdateShipButton">
            <div class="my-3 text-center">
              <button class="btn btn-blue-color text-white" style="border-radius: 10px;"
                @click="showShippingInfo()">Update shipping details</button>
            </div>
          </div>
  
          <div class="row my-12" v-if="showShipping">
            <div class="col-md-4 col-12">
              <fieldset class="curve-box">
                <legend class="subHeadingText">Shipping info</legend>
  
                <div class="row my-2">
                  <div class="col-12 col-md-6 col-lg-5">
                    <label>Date</label>
                    <div class="form-group input-group">
                      <input :disabled="!hideSaveCancel"  type="date" class="form-control cursor UpdateTextAlignment" :min="formattedModelAcceptedDate"  v-model="shippedDate">
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-5">
                    <label>Mode</label>
                    <select :disabled="!hideSaveCancel"  class="form-control" v-model="deliveryMode" style="cursor: pointer;">
                      <option value="" disabled selected>Select</option>
                      <option value="Handdelivery">Hand delivery</option>
                      <option value="Courier">Courier</option>
                    </select>
                  </div>
  
                  <!-- Hand delivery fields -->
                  <div class="col-12 col-md-6 col-lg-5" v-if="deliveryMode === 'Handdelivery'">
                    <label style="margin-top: 15px;">Name</label>
                    <div class="form-group input-group">
                      <input :disabled="!hideSaveCancel"  type="text" class="form-control cursor UpdateTextAlignment" v-model="handDelivery.name">
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-5 mt-3" v-if="deliveryMode === 'Handdelivery'">
                    <label>Phone</label>
                    <div class="form-group input-group">
                      <input type="text" :disabled="!hideSaveCancel"  class="form-control cursor UpdateTextAlignment" v-model="handDelivery.phone">
                    </div>
                  </div>
  
                  <!-- Courier fields -->
                  <div class="col-12 col-md-6 col-lg-5" v-if="deliveryMode === 'Courier'">
                    <label style="margin-top: 15px;">Courier</label>
                    <div class="form-group input-group">
                      <input type="text" class="form-control cursor UpdateTextAlignment"
                        v-model="courierDetails.courierName">
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-6 mt-3" v-if="deliveryMode === 'Courier'">
                    <label>Consignment number</label>
                    <div class="form-group input-group">
                      <input type="text" class="form-control cursor UpdateTextAlignment"
                        v-model="courierDetails.consignmentNumber">
                    </div>
                  </div>
  
                  <div class="col-9  text-center mt-3 ms-5 col-sm-4 col-lg-7" v-if="hideSaveCancel">
                    <div class="d-flex justify-content-around">
                      <div>
                        <button class="btn btn-blue-color text-white" style="border-radius: 10px;"
                        @click="ShipOrder()">Save</button>
                      </div>
                     
                      <div>
  
                        <button class="btn btn-color  text-white" style="border-radius: 10px;"
                        @click="GoBack()">Cancel</button>
                        </div>
                    </div>
                    
  
  
                
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-md-4 col-2"></div>
          </div>
  
  
        </div>
        <div :class="{ 'tab-pane': true, 'active': currentTab === 'tab3' }" id="tab3">
          <!-- Content for Tab 2 -->
          <div class="row my-2">
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Order Date</label>
              <div class="col-lg-4 col-12 my-1">
                    <input type="date" disabled id="datepicker"  
                      class="form-control" v-model="orderedDate" />
                  </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Model confirmed date</label>
              <div class="col-lg-4 col-12 my-1">
                    <input type="text" disabled  
                      class="form-control" v-model="modelConfirmDate" />
                  </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Order accepted date</label>
              <div class="col-lg-4 col-12 my-1">
                    <input type="text" disabled  
                      class="form-control" v-model="orderAcceptedDate" />
                  </div>
            </div>
        
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Order shipped date</label>
              <div class="col-lg-4 col-12 my-1">
                    <input type="date" disabled id="datepicker"  
                      class="form-control" v-model="orderShippedDate" />
                  </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Delivery date</label>
              <div class="col-lg-4 col-12 my-1">
                    <input type="date" disabled id="datepicker"  
                      class="form-control" v-model="deliveryDate" />
                  </div>
            </div>
          </div>
        </div>
       
        <div :class="{ 'tab-pane': true, 'active': currentTab === 'tab4' }" id="tab4">
          <!-- Content for Tab 2 -->
          <div class="row my-2 ">
           
            <div class="col-12 col-md-6 col-lg-12 ">
              <label>Delivery date</label>
              <div class="col-lg-2 col-12 my-1">
                    <input type="date"  id="datepicker"   :disabled="disableDeliveryInput" 
                    :min="formattedModelDeliveryDate"   :max="new Date().toISOString().split('T')[0]"
                      class="form-control" v-model="deliveryDate" />
                  </div>
                  <div class="mt-3" v-if="!enableResendBtnUser && !otpIsVerified &&  hideSendOtp">
                    <button  class="btn w-25 bg-color text-white" @click="GenerateOTP()"  :disabled="!deliveryDate " >Send OTP</button>
                  </div>
            </div>
          </div>
        </div>

        <div class="row noRecordfoundMainDiv text-align text-center mt-2" >

    <div class=" mt-4" v-if="enableResendBtnUser && !otpIsVerified" >
    <button class="btn btn-color text-white tex-align text-center"  :disabled="!isConsultationDataValidNoCheckbox || !timegreater" @click="resendOTPUser" :class=" !isConsultationDataValidNoCheckbox ? 'disableBtn': ''">Resend OTP</button>
    </div>
    <span v-if="!otpIsVerified && enterOTP">
        <div class="EnterOTPdiv mt-2" >
        <label class="enterOTPLabelText" for="">Enter OTP</label>
        <div class="Otpinputs " style="display: flex;justify-content: center;"   >
        <input v-for="(number,index) in otpNumbersUser" ref="otpInputs" :key="index" class="m-2 text-center form-control rounded" type="text" maxlength="1" 
        v-model="otpNumbersUser[index]" @input="validateInputUser(index)" v-on:keyup.enter="combinedOTPUser ? verityOTPUser() : null" />
        </div>
        </div>
        <div class="mt-2" v-if="timeLeftUser > 0 && openOTP ">
    <p  >Resend OTP in <span id="countdowntimer">{{ timeLeftUser }}</span> Seconds</p>
    </div>  
        <div class="text-align text-center">
        <button class="btn btn-blue-color text-white tex-align text-center" :class="!combinedOTPUser ? 'disableBtn': ''" :disabled="!combinedOTPUser"  @click="verityOTPUser()">
        Verify
        </button>
        </div>
    </span>
 
    </div>

        
  
      </div>

     
    </div>
  
  
  

    <div class="mobileStyleDoctor" v-if="newClinic">
      <div class="row clinicTextAlign">
        <div class="clinic_section text-align text-left" style="margin: 20px 0px">
          <fieldset class="curve-box">
            <legend class="subHeadingText">Clinic info</legend>
            <div class="row">
              <div class="col-12">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Name<span class="star">*</span> </label>
                  <input type="text" v-model="this.clinic_name" class="form-control" name="clinic_name"
                    id="clinic_name" />
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-12">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Address <span class="star">*</span></label>
                  <input type="text" v-model="this.current_address" class="form-control" name="current_address"
                    id="current_address">
                </div>
                <div class="form-group my-2">
                  <input type="text" v-model="this.option_address" class="form-control" name="option_address"
                    id="option_address">
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">City <span class="star">*</span></label>
                  <input type="text" v-model="this.city" class="form-control">
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Pincode <span class="star">*</span></label>
                  <input type="text" v-model="this.coPincode" maxlength="6" class="form-control" name="pincode"
                    id="pincode" />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="row">
        <div class="col-10 mb-0 col-sm-9 col-lg-10">
          <p class="mypracticeMobile" style="margin-left: 20px;">Clinicians</p>
        </div>
      </div>
      <div class="hr-border"></div>
      <div class="my-3 mx-3">
        <div class="row mt-2" v-for="(value, index) in clinicianData" :key="index">
  
          <div class="col-3">
            <input type="text" :disabled="!value.isEditable" :value="value.firstName" autocomplete="off"
              class="form-control" />
          </div>
  
          <div class="col-1">
            <button type="button" @click="handleDoctorEdit(value, index)" style="font-size: 20px" id="btn_schedule_add"
              class="btn btn_edit">
              <font-awesome-icon :icon="['fas', 'edit']" />
            </button>
          </div>
          <div class="col-1">
            <input type="checkbox" v-model="value.is_company_active" name="checkbox" :disabled="!value.isEditable" />
          </div>
        </div>
        <button class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="enableClinician()">Add new
          clinician</button>
      </div>
      <div class="row clinicTextAlign" v-if="newClinician">
        <div class="clinic_section text-align text-left" style="margin: 20px 0px">
          <fieldset class="curve-box">
            <legend class="subHeadingText">Clinician info</legend>
            <div class="row">
              <div class="col-12 ">
                <div class="form-group my-2">
                  <label class="receiveConsultation">First Name<span class="star">*</span> </label>
                  <input type="text" v-model="this.firstName" class="form-control" />
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-12">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Last Name<span class="star">*</span></label>
                  <input type="text" v-model="this.lastName" class="form-control">
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Mail ID <span class="star">*</span></label>
                  <input type="text" v-model="this.email" class="form-control">
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group my-2">
                  <label class="receiveConsultation">Phone + WhatsApp <span class="star">*</span></label>
                  <input type="text" v-model="this.mobile" maxlength="10" class="form-control" />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
  
      </div>
      <div class="clinicSubmitBtn" >
        <div style="margin-right: 10px;">
          <button class="btn btn-blue-color text-white"
            @click="editbutton ? updateConsultation() : createConsultation()">{{ this.editbutton ? "Update" : "Submit"
            }}</button>
        </div>
        <div style="margin-left: 10px;">
          <button class="btn btn-color text-white " @click="GoSetting()">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import leftFootPad from '../../common/manage/left-foot-view.vue';
import rightFootPad from '../../common/manage/right-foot-view.vue'

import f0 from '../../../assets/images/f0.jpg'
import f1 from '../../../assets/images/f1.jpg'
import f2 from '../../../assets/images/f2.jpg'
import f3 from '../../../assets/images/f3.jpg'
import f4 from '../../../assets/images/f4.jpg'
import f5 from '../../../assets/images/f5.jpg'
import f6 from '../../../assets/images/f6.jpg'
import f7 from '../../../assets/images/f7.jpg'
import f8 from '../../../assets/images/f8.jpg'
import f9 from '../../../assets/images/f9.jpg'
import f10 from '../../../assets/images/f10.jpg'
import f11 from '../../../assets/images/f11.jpg'
  export default {
    components: {
   
   leftFootPad,
   rightFootPad
 },
  
    props: ["docData"],
    data() {
      return {

enableResendBtnUser:false,
otpIsVerified:false,
otpNumbersUser: ['', '', '', ''],


        selectedMeasurement: {
        key: '',
        text: '',
        image: ''
      },
        planned_delivery_date:"",
        modelAcceptedDate:"",
        orderedDate:"",
        modelConfirmDate:"",
        orderAcceptedDate: "",
        orderShippedDate: "",
        itemId: null,
        newClinic: false,
        newClinician: false,
        disableDeliveryInput:false,
        orderAccepted: true, 
        orderShipped:false,
        hideSaveCancel:true,
        enterOTP:false,
        email: '',
        firstName: '',
        mobile: '',
        lastName: '',
        isActive: false,
        coData: {},
        city: "",
        currentTab: 'tab1',
        clinicConsult: [],
        clinicianData: [],
        leftCanvas:[],
        rightCanvas:[],
        hcpsDoctorsId: '',
        acceptedOrder: [],
        filteredConfirmedOrder: [],
        filteredUploadRecords: [],
        clinic_name: "",
        clinic_id: "",
timeLeftUser: 30,
shippedOrderId:"",

        current_address: "",
        option_address: "",
        clinicName: "",
        coPincode: "",
        editbutton: false,
        hideUpdateShipButton:true,
        currentlyEditedClinic: {},
        currentlyEditedPincodes: {},
        websiteValidationForm: false,
        payloadArray: [],
        nameRegex: /^[A-Za-z.\s]+$/,
        pincodeValidation: /^[1-9](\s*\d){5}$/,
        mobileValidation: /^[6-9]\d{9}$/,
        numberRegex: /^[1-9][0-9]*$/,
        clinicConsultationData: {},
        clinicUpdate: null,
        areasToOffload: {
          Hallux: { left: 'No', right: 'No' },
          '2nd-5th toes': { left: 'No', right: 'No' },
          '1st MPJ': { left: 'No', right: 'No' },
          '2nd MPJ': { left: 'No', right: 'No' },
          '3rd MPJ': { left: 'No', right: 'No' },
          '4th MPJ': { left: 'No', right: 'No' },
          '5th MPJ': { left: 'No', right: 'No' },
          'Lateral Midfoot': { left: 'No', right: 'No' },
          'Medial Midfoot': { left: 'No', right: 'No' },
          'Central Midfoot': { left: 'No', right: 'No' },
          'Complete Midfoot': { left: 'No', right: 'No' },
          'Complete Heel': { left: 'No', right: 'No' },
          'Posterior Heel': { left: 'No', right: 'No' },
          'None_Dont Know': { left: 'No', right: 'No' }
        },
        Offloading_methods: {
          Scooping: { left: 'No', right: 'No' },
          'Reverse Scooping': { left: 'No', right: 'No' },
          Redsitribution: { left: 'No', right: 'No' },
          'Rocker Outersole': { left: 'No', right: 'No' },
          'Wedge Outersole': { left: 'No', right: 'No' },
          Other: { left: 'No', right: 'No' },
          FS_Orthotist_decide: { left: 'No', right: 'No' }
        },
        footMeasurement: {
        'Full length of the foot': { left: '', right: '', text: '.', image: f0 },
        'Length of the foot - 1st MPJ': { left: '', right: '', text: 'Proximal part of the heel to 1st MPJ.', image: f4 },
        'Length of the Foot - 5th MPJ ': { left: '', right: '', text: 'Proximal part of the heel to 5th MPJ ', image: f7 },
        'Width of the foot from the 1st - 5th MPJ': { left: '', right: '', text: 'Measure on the dorsum of the foot from floor to floor', image: f1},
        'Heel width': { left: '', right: '', text: 'Measure on the plantar aspect ', image: f10 },
        'Arch height - Weight bearing': { left: '', right: '', text: 'On Weight bearing (floor to the navicular bone)', image: f5 },
        'Arch Height - Windlass': { left: '', right: '', text: 'On Windlass mechanism (floor to the navicular bone)', image: f11 },

        'Medial malleolus height': { left: '', right: '', text: 'Measure the length from the floor to the tip of the medial malleolus ', image: f2 },
        'Lateral malleolus height': { left: '', right: '', text: 'Measure the length from the floor to the tip of the lateral malleolus', image: f6 },
        'In step circumference': { left: '', right: '', text: 'Measure the foot circumference segment from Floor to Floor at the proximal aspect of the arch (at the talo navicular Joint)', image: "" },
        'Heel diagonal': { left: '', right: '', text: 'Measure the foot circumference segment from Floor to Floor at the talus on the dorsum to the medial and lateral process of calcenium on the plantar aspect', image: f3 },
        'Toe height': { left: '', right: '', text: 'Height of the any toe which is highest when measured from the floor', image: f5 },
        'Limb length (Apparent)': { left: '', right: '', text: '', image: f8 },
        'Limb length (True)': { left: '', right: '', text: '', image: f9 },


      },
        foot_sketch_images: {
          leftFootSketch: '',
          rightFootSketch: ''
        },
  
        foot_photographs: {
          anteriorPhoto: '',
          dorsalPhoto: '',
          leftLateralPhoto: '',
          leftMedialPhoto: '',
          plantarPhoto: '',
          posteriorPhoto: '',
          rightLateralPhoto: '',
          rightMedialPhoto: ''
        },
        gait_videos: {
          anteriorVideoFile: '',
          bilateralVideoFile: '',
          posteriorVideoFile: ''
        },
  
        upload_files: {
          ppsPdfFile: '',
          ppsVideoFile: ''
        },
        clinicDetails: {
          name: '',
          clinician: '',
          clinicianPhone: '',
          current_address: '',
          city: '',
          pincode: '',
          mobile: ''
        },
hideSendOtp:true,

        patientDetails: {
          name: '',
          age: '',
          gender: '',
          height: '',
          weight: '',
          mobile:''
        },
        footArch: {
          left: '',
          right: ''
        },
        JointMobility: {
          left: '',
          right: ''
        },
        otherDatas: {
          size: '',
          model: '',
          recommandations: '',
          caseSheet: ''
        },
        deliveryMode: '', 
        handDelivery: {
          name: '',
          phone: ''
        },
        courierDetails: {
          courierName: '',
          consignmentNumber: ''
        },
        showShipping: false,
        canavasImage:"",
        deliveryDate: '', 
        shippedDate:""          
      };
    },
    computed: {
      formattedModelDeliveryDate() {

    if (this.shippedDate) {
        const [day, month, year] = this.shippedDate.split('/');
        const formattedDate = new Date(`${year}-${month}-${day}`);
        return formattedDate.toISOString().split('T')[0];
    }
    return new Date().toISOString().split('T')[0];
}
,
minDate() {
      return new Date().toISOString().split('T')[0];
    },

      isConsultationDataValidNoCheckbox(){
const mobilevalidation = /^[6-9]\d{9}$/;
return mobilevalidation.test(this.patientDetails.mobile)
},
      areFieldsClinicEmpty() {
        return (
          !this.nameRegex.test(this.clinic_name) ||
          !this.nameRegex.test(this.city) ||
          !this.current_address ||
          !this.pincodeValidation.test(this.coPincode)
        );
      },

     
    },
    methods: {


      validateInputUser(index) {
  const inputValue = this.otpNumbersUser[index];
  const isValid = /^\d$/.test(inputValue); 
  if (!isValid) {
    this.otpNumbersUser[index] = ''; 
  }
  this.$nextTick(() => {
    if (this.otpNumbersUser[index] !== '' && index < this.otpNumbersUser.length - 1) {
        const otpInput = this.$refs.otpInputs;
        if (otpInput && otpInput.length > 0) {
    otpInput[index+1].focus();
  }
}
});
},
      startTimerUser() {
  if (this.timeLeftUser > 0) {
    this.timegreater=false
this.timerUser = setInterval(() => {
  this.timeLeftUser--;
  if (this.timeLeftUser <= 0) {
    this.timegreater=true
    clearInterval(this.timerUser);
    this.enableResendBtnUser = true;
    this.enterOTP=false
  }
}, 1000);
}
},

      GenerateOTP() {
        this.hideSendOtp=false
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
let payload = {
    mobile: this.patientDetails.mobile,
  };

  console.log(payload,"payloasdadssdas")
 axios
    .post(`${BASE_API_URL}/generateotpuser`, payload)
    .then((response)=>{
        if(response.data.type === 'success'){
    this.timeLeftUser= 30;
    clearInterval(this.timerUser);
        this.startTimerUser();
        this.enterOTP=true;
        this.openOTP = true
this.$nextTick(() => {
        const otpInput = this.$refs.otpInputs;
  if (otpInput && otpInput.length > 0) {
    otpInput[0].focus();
  }
});
        }
    })
     },
    verityOTPUser(){


      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;

        this.otpIsVerified = true
        this.enableResendBtnUser=false
        let payloads = {};

//   const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
//         axios
//     .get(`${BASE_API_URL}/verifyotpuser/${this.combinedOTPUser}/${this.publicPayload.mobile}`)
//     .then((response) => {
//       if(response.data.type === 'success'){
//         
//         this.enableResendBtnUser=false
//         this.otpIsVerified = true
//       } else{
//         this.$swal("Please enter valid otp");
//       }
//     })

payloads = {
order_status: "Delivered",
deliveryDate:this.deliveryDate
};

   axios
                .put(`${BASE_API_URL}/footwear-order/${this.shippedOrderId}/update`, payloads, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response?.data) {
                        console.log("Footwear order  delivered successfully!!!");
                        this.isSubmitted = true;
                        this.$swal("Order delivered", "Your order has been successfully delivered!", "success");
                        this.getacceptedOrder()
                    }
                })
                .catch((error) => {
                    console.error("Error from server:", error);
                });
    },

    combinedOTPUser() {
        if (this.otpNumbersUser.some(input => !/^\d$/.test(input))) {
    return '';
  }
  return this.otpNumbersUser.join('');
},
resendOTPUser(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
 axios
 .get(`${BASE_API_URL}/resendotpuser/${this.patientDetails.mobile}`)
   .then((response)=>{
     if(response.data.type === 'success'){
    this.otpNumbersUser=['', '', '', '']
    this.timeLeftUser=30;
    clearInterval(this.timerUser);
      this.startTimerUser();
      this.enterOTP=true;
      this.$nextTick(() => {
        const otpInput = this.$refs.otpInputs;
  if (otpInput && otpInput.length > 0) {
    otpInput[0].focus();
  }
    });
     }
   })
},

      showModal(key) {
      this.selectedMeasurement.key = key;
      this.selectedMeasurement.text = this.footMeasurement[key].text;
      this.selectedMeasurement.image = this.footMeasurement[key].image;
    },
      handleLeftImageUpload(imageData) {
        this.uploadedLeftImage = imageData;
      },
  
      toPascalCase(str) {
    return str.replace(/\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  },
     
      async getacceptedOrder() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        await axios.get(`${BASE_API_URL}/footwear-order`)
          .then((response) => {
            this.acceptedOrder = response.data.filter((orders) => {
              return (
                orders.order_status === "Accepted" ||  orders.order_status === "Shipped" || orders.order_status === "Delivered" 
              )
            });
  
          })
        this.filteredConfirmedOrder = this.acceptedOrder.filter((orders) => {
          return (
            orders.id == this.itemId
          )
        })

        const confirmedOrder = this.filteredConfirmedOrder[0]; 
        if (confirmedOrder.order_status === "Accepted" || confirmedOrder.order_status === "Shipped" || confirmedOrder.order_status === "Delivered" )  {
    this.orderAccepted = false;
    
}


     if ((confirmedOrder.order_status === "Shipped") ||   (confirmedOrder.order_status === "Delivered") ) {
    this.hideUpdateShipButton = false;
    this.showShipping = true;
    this.orderShipped = true;

    console.log( confirmedOrder," this.confirmedOrder")

    this.hideSaveCancel=false
      this.shippedOrderId=confirmedOrder.id
    this.shippedDate = confirmedOrder.shippedDate;

    console.log(this.orderShippedDate,"this.orderShippedDate")
    console.log(this.shippedDate,"this.shippedDate")


    this.deliveryMode = confirmedOrder.deliveryMode;

    const { handDelivery, courierDetails } = confirmedOrder;

    if (handDelivery?.name || handDelivery?.phone) {
        this.handDelivery = { ...handDelivery };
    } else if (courierDetails?.consignmentNumber || courierDetails?.courierName) {
        this.courierDetails = { ...courierDetails };
    }
}
        console.log(confirmedOrder,"Shipped orderssss")
  this.planned_delivery_date =confirmedOrder.planned_delivery_date
  this.orderedDate = new Date(confirmedOrder.createdAt).toISOString().split('T')[0];
  this.orderAcceptedDate =confirmedOrder.orderAcceptedDate
  this.modelConfirmDate =confirmedOrder.modelConfirmedDate

  
  this.orderShippedDate =confirmedOrder.shippedDate
  this.deliveryDate =confirmedOrder.deliveryDate

  if( this.deliveryDate){
    this.hideSendOtp=false
    this.disableDeliveryInput=true
  }


  
// 
  
        
        const colourId = Number(confirmedOrder.selected_colour_model);
  
      const colourResponse = await axios.get(`${BASE_API_URL}/foot-secure/inventory/colour`);
      this.colourData = colourResponse.data?.filter(item => item.id === colourId);
  
      if (this.colourData.length > 0) {
        this.colour_name = this.toPascalCase(this.colourData[0].colour_name)  
        console.log(this.colourData, "Filtered Colour Data");
      } else {
        console.warn("No colour data found for the selected color model.");
      }
  
  
        this.canavasImage=this.filteredConfirmedOrder[0].uploadedDrawImage
  
  
        if (this.filteredConfirmedOrder.length > 0 && this.filteredConfirmedOrder[0].foot_sketch_images) {
          this.foot_sketch_images = {
            leftFootSketch: this.filteredConfirmedOrder[0].foot_sketch_images.leftFootSketch || '',
            rightFootSketch: this.filteredConfirmedOrder[0].foot_sketch_images.rightFootSketch || ''
          };
  
        }
        else if (this.filteredConfirmedOrder.length > 0 && this.filteredConfirmedOrder[0].foot_photographs) {
          this.foot_photographs = {
            anteriorPhoto: this.filteredConfirmedOrder[0].foot_photographs.anteriorPhoto || '',
            dorsalPhoto: this.filteredConfirmedOrder[0].foot_photographs.dorsalPhoto || '',
            leftLateralPhoto: this.filteredConfirmedOrder[0].foot_photographs.leftLateralPhoto || '',
            leftMedialPhoto: this.filteredConfirmedOrder[0].foot_photographs.leftMedialPhoto || '',
            plantarPhoto: this.filteredConfirmedOrder[0].foot_photographs.plantarPhoto || '',
            posteriorPhoto: this.filteredConfirmedOrder[0].foot_photographs.posteriorPhoto || '',
            rightLateralPhoto: this.filteredConfirmedOrder[0].foot_photographs.rightLateralPhoto || '',
            rightMedialPhoto: this.filteredConfirmedOrder[0].foot_photographs.rightMedialPhoto || ''
          }
        }
  
        else if (this.filteredConfirmedOrder.length > 0 && this.filteredConfirmedOrder[0].gait_videos) {
          this.gait_videos = {
            anteriorVideoFile: this.filteredConfirmedOrder[0].gait_videos.anteriorVideoFile || '',
            bilateralVideoFile: this.filteredConfirmedOrder[0].gait_videos.bilateralVideoFile || '',
            posteriorVideoFile: this.filteredConfirmedOrder[0].gait_videos.posteriorVideoFile || '',
  
          }
        }
        else if (this.filteredConfirmedOrder.length > 0 && this.filteredConfirmedOrder[0].upload_files) {
          this.upload_files = {
            ppsPdfFile: this.filteredConfirmedOrder[0].upload_files.ppsPdfFile || '',
            ppsVideoFile: this.filteredConfirmedOrder[0].upload_files.ppsVideoFile || '',
  
          }
        }
  
  
  
        else {
          console.warn("Foot sketch images are not available for this order.");
        }
  
  
        try {
          const response = await axios.get(`${BASE_API_URL}/upload-records`);
  
          if (response?.data) {
            this.filteredUploadRecords = response.data.filter((orders) => {
              return orders.id == this.filteredConfirmedOrder[0].upload_records_data
            })
            const footwearData = this.filteredConfirmedOrder[0]
            this.leftCanvas =JSON.parse(footwearData.uploadedLeftImage)
            this.rightCanvas =JSON.parse(footwearData.uploadedRightImage)
            this.footArch = {
          left: this.toPascalCase(footwearData.foot_arch.left),
          right: this.toPascalCase(footwearData.foot_arch.right)
        };
  
            this.JointMobility = {
              left: this.toPascalCase(footwearData.Joint_mobility.left),
              right: this.toPascalCase(footwearData.Joint_mobility.right)
            }
            this.areasToOffload = footwearData.Areas_to_offload;
          this.footMeasurement =footwearData.footMeasurement

            this.Offloading_methods = footwearData.Offloading_methods;
            this.otherDatas = {
              size: footwearData.size,
              model: footwearData.confirmed_model,
              recommandations: footwearData.Offloading_Recommendations,
              caseSheet: this.filteredUploadRecords[0].caseSheet.split(', ')
            }
  
          } else {
            console.warn("No data received from the API."); // Log if no data is returned
          }
        } catch (error) {
          console.error("Error fetching upload records:", error); // Log any errors
        }
  
  
        try {
          const response = await axios.get(`${BASE_API_URL}/clinic-consultation`);
  
          if (response?.data) {
            this.filteredClincsByID = response.data.filter((orders) => {
              return orders.id == this.filteredUploadRecords[0].doctor_id.clinic_hcp_id
            })
            const clinicData = this.filteredClincsByID[0];
            this.clinicDetails = {
              name: clinicData.clinic_name || '',
              clinician: this.filteredUploadRecords[0].doctor_id.firstName || '', 
              clinicianPhone: this.filteredUploadRecords[0].doctor_id.mobile || '', 
              current_address: clinicData.current_address || '',
              city: clinicData.city || '',
              pincode: clinicData.pincode || '',
              mobile: clinicData.mobile || ''
            };
          } else {
            console.warn("No data received from the API."); // Log if no data is returned
          }
        } catch (error) {
          console.error("Error fetching upload records:", error); // Log any errors
        }
  
  
        try {
          const response = await axios.get(`${BASE_API_URL}/public-user`);
  
          if (response?.data) {
            this.filteredPatientByID = response.data.filter((patient) => {
              return patient.id == this.filteredConfirmedOrder[0].patient_name
            })
  
            const patientData = this.filteredPatientByID[0];

            console.log(patientData,"patientData,")
            this.patientDetails = {
              name: patientData.firstName || '',
              age: patientData.age || '',
              gender: patientData.gender || '',
              mobile:patientData.mobile|| '',
              height: this.filteredConfirmedOrder[0].height || '',
              weight: this.filteredConfirmedOrder[0].weight || ''
            };
  
          } else {
            console.warn("No data received from the API."); // Log if no data is returned
          }
        } catch (error) {
          console.error("Error fetching upload records:", error); // Log any errors
        }
  
  
  
        // .catch((error) => {
        //   console.error("Error fetching Confirmed Orders:", error);
        // });
      },
  
      showShippingInfo() {
        this.hideUpdateShipButton =false
        this.showShipping = true
      },
      GoBack(){
        this.showShipping = false
  
  
      },

      formattedModelAcceptedDate() {
      if (this.orderAcceptedDate) {
        return new Date(this.orderAcceptedDate).toISOString().split('T')[0];
      }
      return new Date().toISOString().split('T')[0]; 
    },
  
      ShipOrder() {
        const order = this.filteredConfirmedOrder[0];
        const orderID = order.id;
  
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  
        let payloads = {
          shippedDate: this.shippedDate,  
          deliveryMode: this.deliveryMode,
          order_status: "Shipped",
        };
  
        if (this.deliveryMode === 'Handdelivery') {
          payloads.handDelivery = {
            name: this.handDelivery.name,
            phone: this.handDelivery.phone,
          };
        } else if (this.deliveryMode === 'Courier') {
          payloads.courierDetails = {
            courierName: this.courierDetails.courierName,
            consignmentNumber: this.courierDetails.consignmentNumber,
          };
        }
  
        const query = this.$route.query;
  
        axios
          .put(`${BASE_API_URL}/footwear-order/${orderID}/update`, payloads, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "==>response");
            if (response?.data) {



     
            this.$swal("Shipped!", "The order has been shipped!", "success");
            window.scrollTo(0, 0);
            this.$router.push({ path: `/doctor/manage/orders`, query });
              console.log("Footwear model updated successfully!!!");
              this.getUserHistory();
            }
          })
          .catch((error) => {
            console.error("Error from server:", error);
          });
      },
  
  
      AcceptOrder() {
        const order = this.filteredConfirmedOrder[0]
        const orderID = order.id
  
        console.log(order,"order")
        console.log(orderID,"orderID")
  
  
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        let payloads = {}
  
        payloads = {
          Offloading_Recommendations: this.otherDatas.recommandations,
          order_status: "Accepted",
          planned_delivery_date:this.planned_delivery_date
        };
  
  
        axios
          .put(`${BASE_API_URL}/footwear-order/${orderID}/update`, payloads, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "==>response");
            if (response?.data) {
  
              this.$swal("Accepted!", "The order has been accepted!", "success");
              
              // this.getUserHistory();
            }
          })
          .catch((error) => {
            console.error("Error from server:", error);
          });
      },
  
      cancel(){
  
        const query = this.$route.query;
  
  
        window.scrollTo(0, 0);
              this.$router.push({ path: `/doctor/manage/orders`, query });
  
      },
  
      async getUplodRecord() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  
        try {
          const response = await axios.get(`${BASE_API_URL}/upload-records`);
  
          if (response?.data) {
            this.filteredUploadRecords = response.data.filter((orders) => {
              return orders.id == this.filteredConfirmedOrder[0].upload_records_data
            })
          } else {
            console.warn("No data received from the API."); // Log if no data is returned
          }
        } catch (error) {
          console.error("Error fetching upload records:", error); // Log any errors
        }
      },
  
      selectTab(tab) {
        this.currentTab = tab;
      },
      clearData() {
        this.clinic_name = '',
          this.current_address = '',
          this.option_address = '',
          this.city = ''
      },
      clearDataDoctor() {
        this.firstName = '',
          this.lastName = '',
          this.email = '',
          this.mobile = ''
      },
      enableClinic() {
        this.newClinic = true;
        this.clearData();
      },
      enableClinician() {
        this.newClinician = true
        this.clearDataDoctor();
        this.editbutton = false;
  
      },
      GoSetting() {
        window.scrollTo(0, 0);
        this.$router.push(`/doctor/clinic/customer-short-name`);
      },
  
      handleDoctorEdit(value, index) {
        this.updateClinicianData = value
        this.editbutton = true;
  
        this.newClinician = true
        this.email = value.email;
        this.firstName = value.firstName;
        this.mobile = value.mobile;
        this.lastName = value.lastName;
  
  
  
        // this.clinic_name = value.clinic_name;
        // this.current_address = value.current_address;
        // this.option_address = value.option_address;
        // this.city = value.city;
        // this.coPincode = value.pincode;  
  
        let previousData = [...this.clinicianData]
        previousData[index].isEditable = true;
        this.clinicianData = previousData;
      },
  
      handleInputEditState(value, index) {
        this.editbutton = true;
        this.clinic_id = value.id
        this.newClinic = true
        this.clinic_name = value.clinic_name;
        this.current_address = value.current_address;
        this.option_address = value.option_address;
        this.city = value.city;
        this.coPincode = value.pincode;
        this.clinicUpdate = value
        let previousData = [...this.clinicConsult]
        previousData[index].isEditable = true;
        this.clinicConsult = previousData;
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
          .get(`${BASE_API_URL}/hcps`)
          .then((response) => {
            if (response.data) {
              const filteredDoctors = response.data.filter((doctors) => {
                return (
                  this.clinic_id == doctors.clinic_hcp_id
                )
              })
              this.clinicianData = filteredDoctors
            }
          })
      },
      async createConsultation() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        if (!this.clinic_name) {
          this.$swal("Please enter the clinic name!");
          return
        }
        if (!this.current_address) {
          this.$swal("Please enter the address!");
          return
        }
        if (!this.city) {
          this.$swal("Please enter the city!");
          return
        }
        if (!this.coPincode) {
          this.$swal("Please enter the pincode!");
          return
        }
        if (this.newClinician) {
  
          if (!this.firstName) {
            this.$swal("Please enter the First Name!");
            return
          }
          if (!this.lastName) {
            this.$swal("Please enter the Last Name!");
            return
          }
          if (!this.mobile) {
            this.$swal("Please enter the mobile number!");
            return
          }
          if (!this.validMobile(this.mobile)) {
            return false;
          }
          if (!this.email) {
            this.$swal("Please enter the email ID!");
            return;
          }
          if (!this.validEmail(this.email)) {
            return false;
          }
        }
        // if(this.areFieldsClinicEmpty){ 
        let clinicPayload = {
          clinic_name: this.clinic_name,
          pincode: this.coPincode,
          city: this.city,
          current_address: this.current_address,
          option_address: this.option_address,
          // clinician_others_id: this.hcpsDoctorsId,
          admin_customer_id: this.coData?.customer?.id,
          role: 'hcp'
        };
        if (this.clinic_id == '') {
          await axios
            .post(`${BASE_API_URL}/clinic-consultation`, clinicPayload)
            .then((response) => {
              if (response.data) {
                const payload = {
                  firstName: this.firstName,
                  lastName: this.lastName,
                  email: this.email,
                  mobile: this.mobile,
                  role: 'hcp',
                  updated_by: 'admin-customer',
                  // clinic_id:response.data.id,
                  clinic_id: response.data.id,
                  pincode: this.coPincode,
                  practiceId: [],
                  doctorPatientId: [],
                  Doctorconsultaion: [],
                  consultation_id: [],
                }
                axios
                  .post(`${BASE_API_URL}/hcps`, payload)
                  .then(async response => {
                    console.log(response.data);
                    this.$swal("Record is created");
                    this.getClinicConsultation();
                  }
                  )
              }
              return response.data
            }
            )
            .catch((error) => {
              return error
            });
        } else {
          const payload = {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            mobile: this.mobile,
            role: 'hcp',
            updated_by: 'admin-customer',
            // clinic_id:response.data.id,
            clinic_id: this.clinic_id,
            pincode: this.coPincode,
            practiceId: [],
            doctorPatientId: [],
            Doctorconsultaion: [],
            consultation_id: [],
          }
          axios
            .post(`${BASE_API_URL}/hcps`, payload)
            .then(async response => {
              console.log(response.data);
              this.$swal("Record is created");
              this.getClinicConsultation();
            }
            )
        }
        // }
      },
  
      async updateConsultation() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
  
        let updateClinicPayload = {
          clinic_name: this.clinic_name,
          city: this.city,
          current_address: this.current_address,
          option_address: this.option_address,
          pincode: this.coPincode,
        }
        await axios
          .put(`${BASE_API_URL}/clinic-consultation/${this.clinicUpdate.id}/update`, updateClinicPayload)
          .then(async (response) => {
            if (response.data) {
              let reupdatePayload = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                mobile: this.mobile,
                clinic_id: this.clinicUpdate.id,
              };
              await axios
                .put(`${BASE_API_URL}/hcps/${this.updateClinicianData.id}/update`, reupdatePayload, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then((response) => {
                  if (response?.data) {
                    this.$swal(
                      "Updated Successfully!!!"
                    );
                  }
                })
            }
          })
  
          .catch(function (error) {
            console.log(error?.response);
          });
      },
  
      validMobile(inputtxt) {
        const mobilevalidation = /^[6-9]\d{9}$/;
        if (inputtxt.match(mobilevalidation)) {
          return true
        } else {
          this.$swal("Please enter the correct mobile number!");
          return false;
        }
      },
      validEmail(inputtxt) {
        var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        var consecutiveSpecialChar = /[\W]{2}/;
  
        if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
          return true;
        } else {
          this.$swal("Please enter the correct email ID!");
          return false;
        }
      },
      getClinicConsultation() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
          .get(`${BASE_API_URL}/clinic-consultation`)
          .then((response) => {
            const filteredValue = response.data.filter((consult) => {
              return (
                this.coData?.customer?.id === consult?.admin_customer_id?.id
              )
            })
            this.clinicConsult = filteredValue;
            const createdData = this.clinicConsult.filter(item => {
              return item.clinician_others_id?.id === this.hcpsDoctorsId && item.role == 'clinician-others'
            });
            this.clinicConsultationData = createdData.length > 0 ? createdData[0] : null;
            this.clinic_name = this.clinicConsultationData.clinic_name,
              this.city = this.clinicConsultationData.city,
              this.coPincode = this.clinicConsultationData.pincode,
              this.current_address = this.clinicConsultationData.current_address
          })
      },
      async getClinicianOthersData() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
          .get(`${BASE_API_URL}/hcps/${this.hcpsDoctorsId}/one`)
  
          .then((response) => {
            this.coData = response.data
            this.coPincode = this.coData.pincode
          })
      },
      async getDocters() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
          .get(`${BASE_API_URL}/hcps`)
          .then((response) => {
            console.log(response.data, "RESPONSE");
          })
      },
    },
    created: async function () {
      this.itemId = this.$route.params.id;
      let hcpsDoctorID = localStorage.getItem('id');
      this.hcpsDoctorsId = JSON.parse(hcpsDoctorID);
  
      // Call async methods in parallel using Promise.all
      await Promise.all([
        this.getClinicianOthersData(),
        this.getClinicConsultation(),
        this.getDocters(),
        this.getUplodRecord(),
        this.getacceptedOrder()
      ]);
    }
  }
  </script>
  <style>
  .green-background {
    background-color: green;
    color: white;
    /* Optional: Change text color to white for better readability */
  }
  
  .offload-table {
    width: 100%;
  
    border-collapse: collapse;
  }
  
  .offload-table th {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
  }
  
  th,
  
  .offload-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ccc;
  }
  .noRecordfoundMainDiv {
display: flex;
align-items: center;
justify-content: center;
}

  th {
    background-color: #f4f4f4;
  }
  
  table {
    border: none;
  }
  
  .foot-column {
    width: 150px;
    /* Adjust the width as needed */
  }
  
  .nav-tabs-container {
    margin-top: 20px;
  }
  
  .nav-tabs .nav-link {
    cursor: pointer;
  }
  
  .tab-content {
    padding: 10px;
    border: 1px solid #ddd;
    border-top: none;
  }
  
  .accordion-body {
    padding: 5px;
  }
  
  .clinicSubmitBtn {
    display: flex;
    justify-content: center;
  }
  
  fieldset.curve-box {
    border: 2px solid #00979e;
    border-radius: 20px;
    padding: 15px;
  }
  
  legend.subHeadingText {
    padding: 4px 10px;
    margin-bottom: 0px;
    display: block;
    float: none;
    width: auto;
    font-size: 20px;
  }
  
  .foot-input {
    width: 60px !important;
    border: none
  }
  
  .allfieldcontent {
    color: red;
    margin-top: 15px
  }
  
  .star {
    color: red;
    font-size: 20px;
  }
  
  .hideOpacity {
    opacity: 0;
  }
  
  .yes-button,
  .no-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .yes-button {
    background-color: #38761D;
    color: #fff;
  }
  
  .no-button {
    background-color: #FF9900;
    color: #fff;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    text-align: center;
    background: #fff;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .cross-icon {
    font-size: 25px;
    color: #cd381c;
    cursor: pointer;
    float: right;
  }
  
  .clinicCreateBtn {
    color: #00979e !important;
    cursor: pointer;
  }
  
  button#btn_clinicconsult_add {
    border-color: transparent;
  }
  
  .disableCreateBtn {
    color: #dddddd !important;
    pointer-events: none;
    cursor: auto;
  }
  
  .clinicTextAlign input {
    text-align: left !important;
    ;
  }
  
  .nav-link.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
  /* .receiveConsultation {
  color: #34989f;
  }
  
  .checkbox-inline {
  color: #34989f;
  } */
  .checkbox-inline.heading {
    font-size: 20px;
    margin-bottom: 0px;
  }
  
  .my-3.subHeading {
    /* color: #34989f; */
    font-size: 18px;
  }
  
  .ms-5.text-color {
    /* color: #34989f; */
    font-size: 18px;
  }
  
  td {
    text-align: left
  }
  
  .page-heading-parter {
    color: #00979e;
    font-size: 28px;
    padding-top: 10px;
    font-weight: bold;
  }
  
  @media screen and (max-width:991px) {
    .mobileStyleDoctor {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 6rem;
    }
  
    .mypracticeMobile {
      margin-left: 20px;
      /* margin-bottom: 10px; */
      margin-top: 1rem;
      color: #000;
      font-weight: 550;
      font-size: 21px;
    }
  
    @media (max-width:576px) {
      .text-center.name-fix {
        font-size: 15px;
      }
  
      fieldset.curve-box {
        border: 2px solid #00979e;
        border-radius: 20px;
        /* padding: 5px; */
      }
  
      fieldset.left-right {
  
        padding: 0px 70px 13px 70px;
  
      }
  
      td {
        padding: 0px 5px !important;
        text-align: left
      }
  
  
  
    }
  }
  </style>
  